// This file is currently used for parsing dates into an input acceptable format, ideally all date manipulation can be in this file

import fecha from 'fecha'
import { DeepPartial } from 'redux'

import { DeliveryWindow } from '@bff/models/og/places'

// @params {string} date - date in ISO string format
function getDateWithZ(date: string) {
  if (!date) return
  // create a new date with Z attached so the date constructor realizes its UTC
  // and corrects it to local time
  if (date[date.length - 1] === 'Z') return new Date(date) // don't add another Z or fecha will barf
  return new Date(date + 'Z')
}

export function parseDateMmDdYyyy(date: string) {
  if (!date) return

  // change to 04/20/2020 format
  return fecha.format(new Date(date), 'MM/DD/YYYY')
}

export function parseDateLong(date: string) {
  if (!date) return

  // change to April 20, 2020
  return fecha.format(getDateWithZ(date), 'longDate')
}

export function parseDateMedium(date: string) {
  if (!date) return

  // change to Apr 20, 2020
  return fecha.format(getDateWithZ(date), 'mediumDate')
}

export function formatDateLongMonthNoYear(date: string) {
  if (!date) return

  // change to April 20
  return fecha.format(new Date(date), 'MMMM D')
}

export function formatDateMediumNoYear(date: string) {
  if (!date) return

  // change to Apr 20
  return fecha.format(new Date(date), 'MMM D')
}

export function formatDateWithDay(date: string) {
  if (!date) return

  // change to Wednesday (April 20)
  return fecha.format(new Date(date), 'dddd (MMMM D)')
}

// @params {string} date - date in ISO string format
function parseDate(date: string) {
  if (!date) return

  // change to Wed, 04/20/69
  return fecha.format(getDateWithZ(date), 'ddd, MM/DD/YY')
}

// @params {string} date - date in ISO string format
export function parseTime(date: string) {
  if (!date) return

  // change to 4:02pm
  return fecha.format(getDateWithZ(date), 'h:mma')
}

// Returns the date in a specific timezone
export function parseDateWithTimeZone(date: string, timeZone: string) {
  if (!date) return

  // change to 4/20/2024
  return getDateWithZ(date).toLocaleDateString('en-US', { timeZone, month: 'numeric', day: '2-digit', year: 'numeric' })
}

// Returns the time in a specific timezone
export function parseTimeWithTimeZone(date: string, timeZone: string) {
  if (!date) return

  // change to 4:02pm
  return getDateWithZ(date)
    .toLocaleTimeString('en-US', { timeZone, hour: 'numeric', minute: '2-digit' }) // returns "4:02 PM"
    .split(' ')
    .join('') // split + join to remove space
    .toLowerCase() // lowercase am/pm
}

export function parseClosingTime(date: string | Date) {
  if (!date) return

  // change to 4:02 PM
  return fecha.format(new Date(date), 'h:mm A')
}

// @params {string} date - date in ISO string format
export function parseDateAtTime(date: string) {
  if (!date) return

  // change to 04/19/69 4:02pm
  return fecha.format(getDateWithZ(date), 'MM/DD/YY h:mma')
}

// @params {string} date - date in ISO string format
export function addDaysToDate(date: string, numberOfDaysToAdd: number) {
  if (!date || !numberOfDaysToAdd) return

  const futureDate = getDateWithZ(date)
  futureDate.setDate(futureDate.getDate() + numberOfDaysToAdd)

  return futureDate.toISOString()
}

export function parseDateMmDd(date: string) {
  if (!date) return

  // change to 04/20 format
  return fecha.format(getDateWithZ(date), 'MM/DD')
}

// returns "11am - 1pm"
export function windowTimeDisplayString(window: DeepPartial<DeliveryWindow>, timeZone: string) {
  return `${parseTimeWithTimeZone(window.timeFrom, timeZone)} - ${parseTimeWithTimeZone(window.timeTo, timeZone)}`
}
