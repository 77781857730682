/**
 * Eta actions
 */

import debounce from 'debounce'
import isEmptyObject from 'is-empty-object'
import last from 'last'

import { getActiveCart } from '@/redux/cart/selectors'
import { track } from 'analytics'
import api from 'api'
import noDeliveryEnums from 'helpers/alerts/no-delivery-enums'
import { defaultCallback } from 'helpers/callbacks'
import promisify from 'helpers/pify'
import { alertTypes } from 'redux/alert/config/types'
import { getPayloadFromState } from 'redux/analytics/get-payloads'
import { etaLoading, etaLoaded } from 'redux/loading/actions'
import { getActiveDepot } from 'redux/location/selectors'
import { setOrderStatusData } from 'redux/order-status/actions'

import t from './actionTypes'

import { setAlertTypeVisibility } from '../alert/actions'

/**
 * Debounce the request eta so that someone rapidly adding/removing from cart
 * doesn't send out a request for every action.
 */
const apiEtaRequest = debounce(api.requestEstimate, 1000)

function requestEtaWrapper(cartId) {
  return promisify(apiEtaRequest)({ cartId })
}

const lastRequestEta = last(requestEtaWrapper)

/**
 * Get delivery information for a given cart and address.
 * @param  {Function} - callback Node style callback with (err, res) args
 * @param  {Boolean}  - shouldRequestQuote - true will fire a requestQuote as well
 */
export function requestEta(cartId, callback = defaultCallback) {
  return (dispatch, getState) => {
    const state = getState()
    const cart = getActiveCart(state)
    const { products: cartProducts } = cart

    dispatch(setAlertTypeVisibility(alertTypes.INVALID_STREET_ADDRESS, true))

    const {
      eta,
      location: { activeLocation },
      user: { userId, xAuthToken }
    } = state

    // if we don't have an address or cartLength, or if the user isn't logged in, let's bail out
    if (isEmptyObject(activeLocation) || !cartProducts.length || !userId || !xAuthToken) {
      dispatch(resetEta())
      return callback()
    }

    // Do not show loading spinner unless this is the first request to populate the eta
    if (isEmptyObject(eta)) {
      dispatch(etaLoading())
    }

    lastRequestEta(cartId)
      .then((eta) => {
        dispatch(receiveEta(eta))
        dispatch(etaLoaded())
        const orderStatusData = etaToOrderStatus(eta)
        dispatch(setOrderStatusData(orderStatusData))
        return callback(null, eta)
      })
      .catch((err) => {
        dispatch(etaLoaded())
        dispatch(setEtaError(err.message || 'Something went wrong.'))
        if (err) return callback(new Error(err))
      })
  }
}

// function to grab specific eta fields and add them to the order-status reducer
function etaToOrderStatus(eta) {
  const etaCopy = { ...eta }
  const { deliveryExpectedStartAt, deliveryExpectedEndAt } = etaCopy
  const orderStatusData = {
    deliveryExpectedStartAt,
    deliveryExpectedEndAt
  }

  return orderStatusData
}

export function resetEta() {
  return (dispatch) => {
    dispatch(etaLoaded())
    dispatch({
      type: t.RESET_ETA
    })
  }
}

function receiveEta(eta) {
  return (dispatch, getState) => {
    dispatch(etaLoaded())
    // we need to save the original state's eta eta for finding if it gets updated
    const state = getState()
    const previousEtaDisplay = state.eta.etadDeliveryTimeDisplay
    const currentDepot = getActiveDepot(state)
    dispatch(trackProductComboError(eta))
    trackNoDriversError(eta, currentDepot)

    dispatch({
      type: t.RECEIVE_ETA,
      payload: eta
    })

    // if the eta has changed, then fire an analytics event
    if (previousEtaDisplay !== eta.etadDeliveryTimeDisplay) {
      track('Checkout.Eta.Updated', getPayloadFromState(getState()))
    }
  }
}

function trackProductComboError({ reasonCode }) {
  return (dispatch, getState) => {
    if (reasonCode === noDeliveryEnums.PRODUCTS_UNAVAILABLE) {
      track('Menu.Banner.CombinationError', getPayloadFromState(getState()))
    }
  }
}

function trackNoDriversError(eta, currentDepot) {
  const { reasonCode, reason } = eta
  const { id: depotId } = currentDepot

  const noDeliveryArray = [
    noDeliveryEnums.NO_DRIVERS,
    noDeliveryEnums.NO_DRIVERS_EXCEPT_CANCELED,
    noDeliveryEnums.ALL_DRIVERS_BUSY
  ]

  if (noDeliveryArray.indexOf(reasonCode) > -1) {
    track('Menu.Banner.NoDriversAvailable', { reasonCode, reason, depotId })
  }
}

function setEtaError(error: string) {
  return {
    type: t.SET_ETA_ERROR,
    payload: error
  }
}

