import { createSelector } from 'reselect'

import { RootState } from '../reducers'

export const isSidebarOpen = createSelector(
  (state: RootState) => state.menu,
  (menu) => menu.isSidebarOpen
)

export const getMenuState = createSelector(
  (state: RootState) => state.menu,
  (menu) => menu
)

export const getActiveMenu = createSelector([getMenuState], (reducer) => {
  return reducer.menus
})

export const getMenuGroups = createSelector([getActiveMenu], ({ groups }) => {
  return groups || []
})

export const getMenuEnabled = createSelector([getMenuState], (reducer) => {
  return reducer.currentMenuEnabled
})

export const getAnnouncementProducts = createSelector([getMenuState], (reducer) => {
  return reducer.announcements
})

export default {
  getMenuState,
  getMenuEnabled,
  getAnnouncementProducts
}
