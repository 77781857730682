import { createSelector } from 'reselect'

import { CartReducerState } from '@/redux/cart/types'
import { MENU_SLUG } from '@helpers/constants'
import { getMenuState } from 'redux/menu/selectors'

const getCart = (state): CartReducerState => state.cart

export const getActiveCart = createSelector([getCart], (cart) => cart[MENU_SLUG])

export const isCartEmpty = createSelector([getActiveCart], (cart) => {
  if (!cart || !cart.products) return true
  return !cart.products.length
})

export const getCartId = createSelector([getActiveCart], (cart) => {
  if (!cart || !cart.id) return 0
  return cart.id
})

export const isCartItem = createSelector([getActiveCart, getMenuState], (cart, menu) => {
  return cart?.products?.some((cartItem) => menu.product?.id === cartItem.id)
})

export const getCartMessage = createSelector([getActiveCart], (cart) => cart.message)

export const getCartSizeMismatch = createSelector([getActiveCart], (cart) => cart.sizeMismatch)

export const getCartExpired = createSelector([getActiveCart], (cart) => cart.expired)

export const getCartCount = createSelector([getActiveCart], (cart) => {
  if (!cart.products) return 0
  return cart.products.reduce((sum, cartItem) => {
    return cartItem.quantity ? sum + cartItem.quantity : sum + 1
  }, 0)
})

export const getCartSubtotal = createSelector([getActiveCart, getCartCount], (cart, cartCount) => {
  if (!cartCount) return 0
  return cart.products.reduce((sum, cartItem) => {
    // let's make sure we have cart.productsList before doing find()!
    const cartItemProduct =
      Array.isArray(cart.productsList) && cart.productsList.find((product) => product.id === cartItem.id)
    if (!cartItemProduct) return sum
    const { price } = cartItemProduct
    const subtotal = price * cartItem.quantity
    return sum + subtotal
  }, 0)
})

export const getCartProducts = createSelector([getActiveCart], (cart) => {
  const cartItems = cart.products
  const cartProductsList = Array.isArray(cart.productsList) ? cart.productsList : []

  if (!cartItems.length) return []

  return cartItems
    .map((cartItem) => {
      const cartProduct = cartProductsList.find((product) => product.id === cartItem.id)
      if (!cartProduct) return null
      const quantity = cartItem.quantity
      const priceDisplay = quantity * cartProduct.price

      return {
        priceDisplay,
        quantity,
        ...cartProduct
      }
    })
    .filter((item) => item !== null)
})
