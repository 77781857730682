/**
 * Actions to set and change address
 */

import t from './actionTypes'

export function setActiveAddress(address) {
  return {
    type: t.SET_PLACE,
    payload: address
  }
}

export function setRecentAddress(address) {
  return {
    type: t.PUSH_RECENT_ADDRESS,
    payload: address
  }
}

export function removePreviousAddress() {
  return {
    type: t.REMOVE_PREVIOUS_ADDRESS
  }
}

export function removeActiveAddress() {
  return {
    type: t.REMOVE_PLACE
  }
}

// this is used when an address pick detects a new depot, we want to store that location
// and only set it once the user confirms the action
export function savePotentialAddress(address) {
  return {
    type: t.SAVE_POTENTIAL_ADDRESS,
    payload: address
  }
}

export function clearPotentialAddress() {
  return {
    type: t.CLEAR_POTENTIAL_ADDRESS,
    payload: {}
  }
}

export function isAddressExact(exact) {
  return {
    type: t.IS_ADDRESS_EXACT,
    payload: exact
  }
}

export function setActiveDepot(depot) {
  return {
    type: t.SET_ACTIVE_DEPOT,
    payload: depot
  }
}

export function setAddress(address) {
  return {
    type: t.SET_ADDRESS,
    payload: address
  }
}

export function setIdScanRequired(idScanRequired) {
  return {
    type: t.SET_ID_SCAN_REQUIRED,
    payload: idScanRequired
  }
}

export function setUsaIdOnly(usaIdOnly) {
  return {
    type: t.SET_US_ID_ONLY,
    payload: usaIdOnly
  }
}

export default {
  clearPotentialAddress,
  isAddressExact,
  removeActiveAddress,
  removePreviousAddress,
  savePotentialAddress,
  setActiveAddress,
  setRecentAddress,
  setActiveDepot,
  setAddress,
  setIdScanRequired,
  setUsaIdOnly
}
