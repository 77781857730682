import { CartItem } from "./types"

const arrayInsert = (array: Array<any>, item: any, index: number): Array<any> => [...array.slice(0, index), item, ...array.slice(index + 1)]

/**
 * Adds items to cart
 * @param  {{id: number, quantity: number}[]}  cart       array of product ids with associated quantities
 * @param  {[String]} id         id of product in operation
 * @param  {[Number]} quantity   quantity of product in operation
 * @return {{id: number, quantity: number}[]}             new cart with added products
 */
export const addToCart = (cart: CartItem[], id: number, quantity: number, price: number): CartItem[] => {
  const cartItem = cart.find((cartItem) => cartItem.id === id)

  const newCartItem = { id, quantity, price }

  if (!cartItem) {
    cart.push(newCartItem)
    return cart
  } else {
    const cartItemIndex = cart.indexOf(cartItem)
    return arrayInsert(cart, newCartItem, cartItemIndex)
  }
}

/**
 * Remove items from cart
 * @param  {Array}  cart       array of product ids with associated quantities
 * @param  {String} id         id of product in operation
 * @param  {Number} quantity   quantity of product in operation
 * @return {Array}             new cart with removed products
 */
export const removeFromCart = (cart: CartItem[], id: number, quantity: number): CartItem[] => {
  if (!quantity) {
    return cart.filter((cartItem) => cartItem.id !== id)
  }

  let cartItem
  // Perform 2 searches - first to match both id and quantity,
  // second if there is no match that way to match just id
  cartItem = cart.find((item) => item.id === id && item.quantity === quantity)
  if (!cartItem) {
    cartItem = cart.find((item) => item.id === id)
  }

  if (!cartItem) {
    return cart
  } else {
    const cartItemIndex = cart.indexOf(cartItem)
    if (typeof quantity === 'string' || quantity >= cartItem.quantity) {
      cart.splice(cartItemIndex, 1)
      return cart
    } else {
      const newCartItem = { id, quantity }

      return arrayInsert(cart, newCartItem, cartItemIndex)
    }
  }
}
