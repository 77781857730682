/**
 * Helper file for all things related to payments
 */

// add more time to checkout for payment processing
export const CASH_PAYMENT_METHOD = 0
const CREDIT_PAYMENT_METHOD = 1 // credit card online
export const PRESENT_CREDIT_CARD_PAYMENT_METHOD = 2
export const PRESENT_DEBIT_CARD_PAYMENT_METHOD = 3
export const DEBIT_CASH_BACK_PAYMENT_METHOD = 4
export const ACH_PAYMENT_METHOD = 5
const DEBIT_CARD_ONLINE_METHOD = 6
export const STORE_CREDIT_PAYMENT_METHOD = 9

export const PAYMENT_METHOD_TEXT_MAPPING = {
  0: 'Cash (in person)',
  2: 'Pay with Credit (in person',
  3: 'Pay with Debit (in person)',
  4: 'Pay with Debit (in person)',
  5: 'Pay with Checking Account',
  6: 'Pay with Credit'
}

export const NOT_ONLINE_CARD_PAYMENT_METHOD_IDS = [
  CASH_PAYMENT_METHOD,
  PRESENT_CREDIT_CARD_PAYMENT_METHOD,
  PRESENT_DEBIT_CARD_PAYMENT_METHOD,
  DEBIT_CASH_BACK_PAYMENT_METHOD,
  ACH_PAYMENT_METHOD,
  DEBIT_CARD_ONLINE_METHOD
]
export const ONLINE_PAYMENT_METHODS = [CREDIT_PAYMENT_METHOD, ACH_PAYMENT_METHOD, DEBIT_CARD_ONLINE_METHOD]
export const DEBIT_THRESHOLD_AMOUNT = 500
export const ACH_ACCOUNTS_SYNCED = 'ACH_ACCOUNTS_SYNCED'

// Exporting this since we don't want a bunch of !== 0 || truthy checks everywhere
export const isValidPaymentId = (paymentId) => {
  return paymentId === CASH_PAYMENT_METHOD || !!paymentId
}

export const getCashBackText = (customerTerminalCharge, cashBack) => {
  return `Your card will be charged $${customerTerminalCharge}, and you’ll receive $${cashBack} in change`
}
export const CASH_BACK_INFO_TEXT =
  'This payment option utilizes an ATM withdrawal to facilitate payment to the retailer. As a result, your card will be charged $1 to $5 more than your order total — an amount you will receive as cash back. Your bank may charge an ATM fee.'

export const paymentTypeTrackingOptions = {
  [CASH_PAYMENT_METHOD]: 'cash',
  [CREDIT_PAYMENT_METHOD]: 'credit_card_online',
  [PRESENT_CREDIT_CARD_PAYMENT_METHOD]: 'credit_card_in_person',
  [PRESENT_DEBIT_CARD_PAYMENT_METHOD]: 'debit_card_in_person',
  [DEBIT_CASH_BACK_PAYMENT_METHOD]: 'debit_card_in_person',
  [DEBIT_CARD_ONLINE_METHOD]: 'debit_card_online',
  [ACH_PAYMENT_METHOD]: 'ach'
}
