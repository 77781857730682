import { createSelector } from 'reselect'

import { etaInterval, etaIntervalFormatted } from 'redux/misc-selectors/time-range'

import { RootState } from '../reducers'

export const getEta = (state: RootState) => state.eta

export const getEtaError = createSelector([getEta], (eta) => eta.error)

const ONE_SECOND_IN_MILLISECONDS = 1000

const getEstimateTime = createSelector([getEta], (eta) => `${eta.estimatedDeliveryDateTime}Z`)

