/**
 * Actions to indicate loading states
 */

import t from './actionTypes'


function setTriggeredBy(triggeredBy) {
  return {
    type: t.SET_TRIGGERED_BY,
    payload: triggeredBy
  }
}

function setToggleAddress() {
  return {
    type: t.TOGGLE_ADDRESS
  }
}

export function setAddressVisibility(isAddressVisible) {
  return {
    type: t.SET_ADDRESS_VISIBILITY,
    payload: isAddressVisible
  }
}

export function setAddressSuggestions(suggestions) {
  return {
    type: t.SET_ADDRESS_SUGGESTIONS,
    payload: suggestions
  }
}

export function setResidentialOnlyError(resOnly) {
  return {
    type: t.SET_RESIDENTIAL_ONLY_ERROR,
    payload: resOnly
  }
}

export function toggleAddress(redirectLocation = null, triggeredBy = 'address-bar') {
  return (dispatch) => {
    if (redirectLocation) {
      dispatch(setShouldRedirect(true))
      dispatch(setRedirectLocation(redirectLocation))
    }
    dispatch(setToggleAddress())
    dispatch(setResidentialOnlyError(false))
    dispatch(setTriggeredBy(triggeredBy))
  }
}

export function setShouldRedirect(shouldRedirect) {
  return {
    type: t.SET_SHOULD_REDIRECT,
    payload: shouldRedirect
  }
}

export function setRedirectLocation(location) {
  return {
    type: t.SET_REDIRECT_LOCATION,
    payload: location
  }
}
