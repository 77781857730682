import { createSelector } from 'reselect'

import { RootState } from '../reducers'

const getCheckoutState = (state: RootState) => state.checkout

export const getCheckoutError = createSelector([getCheckoutState], (checkout) => checkout.error)

export const getDepotMismatch = createSelector([getCheckoutState], (checkout) => checkout.depotMismatch)

export const getCheckoutMessage = createSelector([getCheckoutState], function (checkout) {
  return checkout.message
})

export const getCheckoutMessageStatusCode = createSelector([getCheckoutState], function (checkout) {
  return checkout.messageStatusCode
})

export const getDeliveryInstructionNotes = createSelector([getCheckoutState], (checkout) => checkout.deliveryNotes)

export const getisACHFixDrawerOpen = createSelector([getCheckoutState], (checkout) => checkout.isACHFixDrawerOpen)

export const getIsAsapDelivery = createSelector([getCheckoutState], (checkout) => checkout.isAsapDelivery)

export const getDeliveryWindowId = createSelector([getCheckoutState], (checkout) => checkout.deliveryWindowId)

export const getAutoSelectedPaymentMethod = createSelector([getCheckoutState], (checkout) => checkout.autoSelectedPaymentMethod)
