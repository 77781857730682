/**
 * Selectors for auth store
 */

import { createSelector } from 'reselect'

import { RootState } from '../reducers'

const DEFAULT_ERROR_MESSAGE = 'Something went wrong'

/**
 * Get the auth object from state
 */
export const getAuth = (state: RootState) => state.auth

// since we're no longer storing xAuthToken, id or email in the auth store .. let's also use a getUser
// function that fetches state.user
const getUser = (state: RootState) => state.user

/**
 * Get errors related to auth
 */
export const getAuthError = createSelector([getAuth], (auth) => auth.err)

/**
 * Get the user id
 */
export const getUserId = createSelector([getUser], (user) => user.userId)

export const getToken = createSelector([getUser], (user) => user.xAuthToken)

/**
 * Return true if the user is logged in
 */
export const isLoggedIn = createSelector([getToken], (token) => !!token)

/**
 * Get the user's email
 */
export const getUserEmail = createSelector([getUser], (user) => user.email)

/**
 * Get the user's zip code
 */
export const getUserZipcode = createSelector([getUser], (user) => user.zipcode)

export const getUserPhoneNumber = createSelector([getUser], (user) => user.mobilePhone)

/**
 * Get errors related to an invalid login attempt
 */
export const getInvalidLoginAttempt = createSelector([getAuth], (auth) => auth.invalidLoginAttempt)

/**
 * Get errors related to an invalid password recovery attempt
 */
export const getInvalidPasswordRecoveryAttempt = createSelector(
  [getAuth],
  (auth) => auth.invalidPasswordRecoveryAttempt
)

export const getPasswordRecoveryIdentifier = createSelector([getAuth], (auth) => auth.passwordRecoveryIdentifier)

export const getPhoneConfirmError = createSelector([getAuth], (auth) => auth.phoneConfirmError)

export const getProfileChangeAttempt = createSelector([getAuth], (auth) => Boolean(auth.attemptProfileChange))
