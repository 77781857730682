import { DeepPartial } from 'redux'

import { Brand, BrandDirectory } from '@bff/models/og/brand'
import { BlobContentInfo, CatalogItem } from 'bff/models/og/catalog'
import { Product } from 'bff/models/og/menu'

import type { IncomingHttpHeaders } from 'http'

// OG caches it menus response for 10 mins - setting our expiration limit to the same time
export const MENU_CACHE_EXPIRATION = 10 * 60 // 10 mins in seconds

export const DISABLE_BFF_DATA_LOGGING = process.env.DISABLE_BFF_DATA_LOGGING

export const SERVER_API_URL = `${process.env.API_URL}/api` // @TODO rename this to PINE_API_URL
export const GEOLOCALITY_API_URL = `${process.env.GEOLOCALITY_URL}/api`

const dev = process.env.NODE_ENV !== 'production'
export const WWW_BASE_URL = dev ? 'https://localhost:3000' : process.env.WWW_BASE_URL
export const NEXTJS_API_PATH = '/api/v2'

const iOS = /iPad|iPhone|iPod/
const android = /android/i
const webview = /eaze-webview/

const detectUserAgent = (regex: RegExp, headers: IncomingHttpHeaders) => regex.test(headers?.['user-agent'] || '')

export const isIosApp = (headers: IncomingHttpHeaders) =>
  detectUserAgent(iOS, headers) && detectUserAgent(webview, headers)
export const isAndroidApp = (headers: IncomingHttpHeaders) =>
  detectUserAgent(android, headers) && detectUserAgent(webview, headers)
export const isConsumerApp = (headers: IncomingHttpHeaders) => detectUserAgent(webview, headers)

export const VAPORIZER_REGEX = /(vap(orizer|orization|e|or){1}s*\s*)/gi

// Title of vaporizer category on iOS webview app for passing anti-vape app reviewers
export const IOS_VAPORIZER_CATEGORY_NAME = 'Cartridges'

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const pick = (object: any, keys: string[]): any => {
  return keys.reduce((obj, key) => {
    if (object && Object.prototype.hasOwnProperty.call(object, key)) {
      obj[key] = object[key]
    }
    return obj
  }, {})
}

export const SPLIT_EXPERIMENTS = {
  UPSELL_AT_CART: 'upsell_cart'
}

export const SPLIT_TREATMENTS = {
  UPSELL_AT_CART: {
    ON: 'on',
    OFF: 'off'
  }
}

/**
 * Groups a list of brands alphabetically:
 * @param brands - a list of brand objects
 */
export function groupBrands(brands: Brand[]): BrandDirectory[] {
  if (!brands.length) return []

  // Define the labels for each group
  const labels = ['Brands A - G', 'Brands H - R', 'Brands S - #']

  // Initialize the groups
  const groupedBrands: Record<string, BrandDirectory> = labels.reduce((acc, label) => {
    acc[label] = { group: label, brands: [] }
    return acc
  }, {} as Record<string, BrandDirectory>)

  // Sort Brands
  const sortedBrands = brands.sort((a, b) => a.name.localeCompare(b.name))

  // Group brands based on the first character
  sortedBrands.forEach((brand) => {
    const firstLetter = brand.name[0]?.toLowerCase()

    if (firstLetter >= 'a' && firstLetter <= 'g') {
      groupedBrands['Brands A - G'].brands.push(brand)
    } else if (firstLetter >= 'h' && firstLetter <= 'r') {
      groupedBrands['Brands H - R'].brands.push(brand)
    } else {
      // Include numbers and special characters in the 'Brands S - #' group
      groupedBrands['Brands S - #'].brands.push(brand)
    }
  })

  // Sort the 'Brands S - #' group to move numeric brands to the end
  groupedBrands['Brands S - #'].brands.sort((a, b) => {
    const isANumber = /^\d/.test(a.name)
    const isBNumber = /^\d/.test(b.name)

    // Numbers come last
    if (isANumber && !isBNumber) return 1
    if (!isANumber && isBNumber) return -1

    // Alphabetical order within each group
    return a.name.localeCompare(b.name)
  })

  // Return the groups in order
  return labels.map((label) => groupedBrands[label])
}

export function isProduct(item: Product | CatalogItem): item is Product {
  return !isNaN(Number(item.id))
}

export function getQueryParam(queryParam: string | string[]) {
  return Array.isArray(queryParam) ? queryParam[0] : queryParam
}

// Check if 'images' is an array and has at least one element before accessing the first element's 'downloadUrl'.
// This ensures we only attempt to access 'downloadUrl' if the array is not empty, improving code clarity and safety.
const UNAVAILABLE_IMAGE_URL = '/static/images/image-unavailable-min.png'
export function sanitizeCatalogItemImageUrl(images: DeepPartial<BlobContentInfo>[]): string {
  return Array.isArray(images) && images.length > 0 && images[0]?.downloadUrl
    ? images[0].downloadUrl
    : UNAVAILABLE_IMAGE_URL
}
