import { createSelector } from 'reselect'

import { RootState } from '../reducers'

const getLoading = (state: RootState) => state.loading

export const getAddressLoading = createSelector([getLoading], (loading) => loading.address)

export const getCancelOrderLoading = createSelector([getLoading], (loading) => loading.cancelOrder)

export const getPostOrderPending = createSelector([getLoading], (loading) => loading.creatingOrder)

export const getCreatingSupportTicketLoading = createSelector([getLoading], (loading) => loading.creatingSupportTicket)

export const getDeliveriesLoading = createSelector([getLoading], (loading) => loading.deliveries)

export const getEtaLoading = createSelector([getLoading], (loading) => loading.eta)

export const getHelpCenterArticleLoading = createSelector([getLoading], (loading) => loading.helpArticle)

export const getLoginLoading = createSelector([getLoading], (loading) => loading.login)

export const getMenuProductsLoading = createSelector([getLoading], (loading) => loading.menuProducts)

export const getPhoneConfirmLoading = createSelector([getLoading], (loading) => loading.phoneConfirm)

export const getPhoneInputLoading = createSelector([getLoading], (loading) => loading.phoneInput)

export const getProductsLoading = createSelector([getLoading], (loading) => loading.products)

export const getQuoteLoading = createSelector([getLoading], (loading) => loading.quote)

export const getResetPasswordLoading = createSelector([getLoading], (loading) => loading.resetPassword)

export const getSignupLoading = createSelector([getLoading], (loading) => loading.signup)

export const getStateIdImageLoading = createSelector([getLoading], (loading) => loading.stateIDImage)

export const getTaxFeesLoading = createSelector([getLoading], (loading) => loading.taxFees)

export const getUserProfileLoading = createSelector([getLoading], (loading) => loading.userProfile)

export const getUserStatusLoading = createSelector([getLoading], (loading) => loading.userStatus)

export const getPersonaLoading = createSelector([getLoading], (loading) => loading.personaLoading)

export const getBrandProductsLoading = createSelector([getLoading], (loading) => loading.brandProducts)
